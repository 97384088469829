export const typeInfo = [
 {name:"RADIOLOGIA", items:["RADIOLOGIA","MENU_1"], color:"#e79632"},
 {name:"NEUROLOGIA", items:["NEUROLOGIA","MENU_2"], color:"#3264e7"},
 {name:"ESPECIALIDADES", items:["ESPECIALIDADES","MENU_3"], color:"#ca1917"},
 {name:"HOSPITALIZACION", items:["HOSPITALIZACION","MENU_4_1"], color:"#29b507"},
 {name:"RECEPCION", items:["RECEPCION","MENU_4_2"], color:"#b5079a"},
 {name:"SIN_DATOS", items:["null"], color:"#020202"},
] 

export const getFormatedItem = (nombre) => {
  
  const item = typeInfo.find(a => a.items.indexOf(nombre)>=0)

  return {
    nombre: nombre === "null" ? "SIN_DATOS" : nombre,
    color: item ? item.color : getRandomColor(),
    name: item ? item.name : nombre
  }
}

export const getTotalesItem = (item,itemColor) => {

  const acumulados = {};

  // Recorrer el array original
item.forEach(entry => {
  // Iterar sobre las claves del objeto actual
  Object.keys(entry).forEach(key => {
    // Ignorar la clave 'dia'
    if (key !== 'dia') {
      // Sumar el valor al acumulador correspondiente
      acumulados[key] = (acumulados[key] || 0) + entry[key];
    }
  });
});

// Convertir el objeto acumulados en un array de objetos {name, value}
const datosPie = Object.keys(acumulados).map(key => ({
  name: key,
  value: acumulados[key],
}));

  return addColorToArray(datosPie,itemColor)
}

export const getDataFormated = (active,total) => {
   // Crear el nuevo array con las coincidencias
   total.forEach(item1 => {
    const match = active.find(item2 => item2.name === item1.name);
    if (match) {
      item1.value = match.value !== undefined ? match.value : 0;
    }
  });
  return total;
}

// Función para añadir color al array original
const addColorToArray = (originalArray, colorArray) => {
  return originalArray.map(item => {
    // Buscar coincidencia en arrayColores por el campo "nombre"
    const match = colorArray.find(colorItem => colorItem.nombre === item.name);
    return {
      ...item,
      color: match ? match.color : "#000000" // Color predeterminado si no hay coincidencia
    };
  });
};

const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const typeInfoOficinas = [
  {name:"CONSULTAS", items:["CONSULTAS","MENU_1"], color:"#e79632"},
  {name:"REHABILITACION", items:["REHABILITACION","MENU_2"], color:"#3264e7"},
  {name:"ESPECIALIDADES", items:["ESPECIALIDADES","MENU_3"], color:"#ca1917"},
  {name:"HOSPITALIZACION", items:["HOSPITALIZACION","MENU_4_1"], color:"#29b507"},
  {name:"RECEPCION", items:["RECEPCION","MENU_4_2"], color:"#b5079a"},
  {name:"SIN_DATOS", items:["null"], color:"#020202"},
 ] 
 
 export const getFormatedItemOficinas = (nombre) => {
   
   const item = typeInfoOficinas.find(a => a.items.indexOf(nombre)>=0)
 
   return {
     nombre: nombre === "null" ? "SIN_DATOS" : nombre,
     color: item ? item.color : getRandomColor(),
     name: item ? item.name : nombre
   }
 }