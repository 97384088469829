import { useContext }                   from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthContext }                  from "../auth/AuthContext";
import { HospitalAdministracion }       from "../components/HospitalAdministracion";
import { HospitalDetalle }              from "../components/HospitalDetalle";
import { HospitalOperacion }              from "../components/HospitalOperacion";
import { HospitalEstadistica }          from "../components/HospitalEstadistica/HospitalEstadistica";
import { MenuPage }                     from "../components/MenuPage/MenuPage";
import { Siga }                         from "../components/Siga";
import { WithoutMenu }                  from "../components/WithoutMenu";
import { ProtectedAnyRoute }            from "./ProtectedAnyRoute";
import { ProtectedLoginPage }           from "./ProtectedLoginPage";
import { ProtectedRoute }               from "./ProtectedRoute";
import { OficinasDetalle } from "../components/Oficinas/OficinasDetalle";
import { HospitalGestion } from "../components/HospitalGestion/HospitalGestion";
import { OficinasOperacion } from "../components/Oficinas/OficinasOperacion";
import { OficinasEstadistica } from "../components/Oficinas/OficinasEstadistica";
import { HospitalGrafica } from "../components/HospitalEstadistica/HospitalGrafica";
import { OficinasGrafica } from "../components/Oficinas/OficinasGrafica";

export const AppRouter = () => {
  const {logged} = useContext(AuthContext)

  return (
    <BrowserRouter>
      <Routes>
        {/* Not logged */}
        <Route path="/login" element={<ProtectedLoginPage/>}/>
        <Route path="/" element={<ProtectedAnyRoute/>}/>

        {/* Logged */}
        {/* This conditional is to fix a bad effect UI */}
        {logged && (
        <Route element={<MenuPage/>}>
          <Route path="/hospital" element={<ProtectedRoute path="/hospital"><HospitalDetalle/></ProtectedRoute>}/>
          <Route path="/hospital/detalle" element={<ProtectedRoute path="/hospital/detalle"><HospitalDetalle/></ProtectedRoute>}/>
          <Route path="/hospital/operacion" element={<ProtectedRoute path="/hospital/operacion"><HospitalOperacion/></ProtectedRoute>}/>
          <Route path="/hospital/gestion" element={<ProtectedRoute path="/hospital/gestion"><HospitalGestion/></ProtectedRoute>}/>
          <Route path="/hospital/estadistica" element={<ProtectedRoute path="/hospital/estadistica"><HospitalEstadistica/></ProtectedRoute>}/>
          <Route path="/hospital/graficas" element={<ProtectedRoute path="/hospital/graficas"><HospitalGrafica/></ProtectedRoute>}/>
          <Route path="/hospital/administracion" element={<ProtectedRoute path="/hospital/administracion"><HospitalAdministracion/></ProtectedRoute>}/>
          <Route path="/siga" element={<ProtectedRoute path="/siga"><Siga/></ProtectedRoute>}/>
          <Route path="/without-menu" element={<ProtectedRoute path="/without-menu"><WithoutMenu/></ProtectedRoute>}/>
          <Route path="/oficinas" element={<ProtectedRoute path="/oficinas"><OficinasDetalle/></ProtectedRoute>}/>
          <Route path="/oficinas/detalle" element={<ProtectedRoute path="/oficinas/detalle"><OficinasDetalle/></ProtectedRoute>}/>
          <Route path="/oficinas/operacion" element={<ProtectedRoute path="/oficinas/operacion"><OficinasOperacion/></ProtectedRoute>}/>
          <Route path="/oficinas/estadistica" element={<ProtectedRoute path="/oficinas/estadistica"><OficinasEstadistica/></ProtectedRoute>}/>
          <Route path="/oficinas/graficas" element={<ProtectedRoute path="/oficinas/graficas"><OficinasGrafica/></ProtectedRoute>}/>
        </Route>
        )}

        {/* Any route */}
        <Route path="*" element={<ProtectedAnyRoute/>}/>
      </Routes>
    </BrowserRouter>
  );
}