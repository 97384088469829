import './OficinasEstadistica.css';
import React, { useEffect, useState }   from 'react';
import { Button, Col, DatePicker, Form, Row, Space, Typography, Table } from 'antd';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, 
  XAxis, YAxis, Pie, PieChart, Cell, Rectangle } from "recharts";
import {DownloadOutlined} from '@ant-design/icons';
import dayjs                            from "dayjs";
import moment                           from 'moment';
import { ServicesOficinas }             from '../../services/ServicesOficinas';
import _                                from 'lodash';
import { getDataFormated, getFormatedItem, getTotalesItem }               from '../../utils/Utils';

// Constants
const { Title } = Typography;
const stateInitial = {
  typeCalls: [],
  uniqueTypeCalls: [],
  repetitionsCalls: [],
  kpi:{},
  kpi_total:[],
  kpi_privadas:[],
  inicio: "",
  final: ""
}

  
  
export const OficinasEstadistica = () => {
  ////////
  // Hooks
  ////////
  const [state, setState] = useState(stateInitial);
  const [totalCalls, setTotalCalls] = useState(0);
  const [activeIndex, setActiveIndex] = useState("LLamadas totales");
  
  useEffect(() => {
    // Update title
    document.title = "Icot - Hospital estadística";    
    getData({ start: moment().set("date",1), fin: moment() });
  }, []);

  const getData = async(values) => {
    const start = values.start.format("YYYY-MM-DD");
    const fin = values.fin.format("YYYY-MM-DD");
    setState(prevState => (
      {
        ...prevState,
        inicio: start,
        final: fin,
        kpi_total:[],
        kpi_privadas:[],
      }
    ));
    getTypeCalls(start, fin);
    getDataTotales(start, fin);
    getDataPrivadas(start, fin);
  }
  const getDatos = async() => {
    await ServicesOficinas.getDatos(state.inicio, state.final);
  }
  const getDataTotales = async(start,fin) => {
    const kpi_total = await ServicesOficinas.getLlamadasTotales(start, fin);
    // Update state
    setState(prevState => (
      {
        ...prevState,
        kpi_total
      }
    ));
  }
  const getDataPrivadas = async(start,fin) => {
    const kpi_privadas = await ServicesOficinas.getLlamadasPrivadas(start, fin);
    // Update state
    setState(prevState => (
      {
        ...prevState,
        kpi_privadas
      }
    ));
  }

  const getTypeCalls = async(start, fin) => {
    const resp = await ServicesOficinas.getTypeCalls(start, fin);
    const aux = [], uniqueTypeCalls = [], typeCalls = [];
    // 1º Search unique values
    resp.forEach(i => {
      i.opciones.forEach(o => {
        aux.push(o);
      })
    });
    const unrollArray = _.groupBy(aux, "nombre");
    
    // 2º Fill color
    _.map(unrollArray, (value, key) => {
      uniqueTypeCalls.push(getFormatedItem(key))
    });

    // 3ª Fill values to show in graph
    resp.forEach(i => {
      const aux_2 = {};
      aux_2.dia = i.dia;
      i.opciones.forEach(o => {
        let property = o.nombre === null ? "SIN_DATOS" : o.nombre;
        aux_2[property] = o.total;
      });
      typeCalls.push(aux_2);
    });


    //variable para rellenar pieChart

    setTotalCalls(getTotalesItem(typeCalls, uniqueTypeCalls));

    // Update state
    setState(prevState => (
      {
        ...prevState,
        typeCalls,
        uniqueTypeCalls,
      }
    ));
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload.length > 0 && payload.length) {
      return (
        <div className='ycs-container-tooltip'>
          <p style={{ fontWeight: "bold" }}>{label}</p>
          {payload.map(i => (
            <p style={{ color: i.fill }}>{i.dataKey}: {i.value}</p>
          ))}
        </div>
      );
    }
    return null;
  };

  const CustomTooltipPie = ({ active, payload, label }) => {
    if (active && payload.length > 0 && payload.length) {
      return (
        <div className='ycs-container-tooltip'>
          <p style={{ fontWeight: "bold" }}>{label}</p>
          {payload.map(i => (
            <p style={{ color: i.fill }}>{i.name}: {i.value}</p>
          ))}
        </div>
      );
    }
    return null;
  };

  ///para el piechart
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  //evento click en grafica barras para cambiar pieChart
  const handleClick = (data,index) => {
    if (data && data.activeLabel) {
      setActiveIndex("LLamadas del día "+data.activeLabel);
      setTotalCalls(_.clone(getDataFormated(data.activePayload,totalCalls)));
    } else {
      setActiveIndex("LLamadas totales"); // Si clicas fuera de un sector, resetea
      setTotalCalls(getTotalesItem(state.typeCalls, state.uniqueTypeCalls));
    }
  };

  

  const columnsTotales = [
    {
      title: 'Llamadas',
      dataIndex: 'todas',
      key: 'todas',
      render: (text, record, index) => (
        <>
        <Typography.Title level={2} style={{ margin: 0 }}>
        {['Totales','Privado'][index]}
        </Typography.Title>
      </>
      ),
    },
    {
      title: 'Llamadas',
      dataIndex: 'todas',
      key: 'todas',
      align: 'center',
      render: (text, record, index) => (
        <>
          <Typography.Title level={2} style={{ margin: 0 }}>
          {text}
          </Typography.Title>
          {index>0 &&
            <Typography.Title level={5} style={{ margin: 0 }}>
            {((text/state.kpi_total.todas)*100).toFixed(2)} %
            </Typography.Title>
          }
        </>
      ),
    },
    {
      title: 'Respondidas',
      dataIndex: 'respondidas',
      key: 'respondidas',
      align: 'center',
      render: (text, record, index) => (
        <>
          <Typography.Title level={2} style={{ margin: 0, color: 'green' }}>
          {text-record.absorbida}
          </Typography.Title>
          <Typography.Title level={5} style={{ margin: 0, color: 'green' }}>
          {((text/record.todas)*100).toFixed(2)} %
          </Typography.Title>
        </>
      ),
    },
    {
      title: 'Absorbidas',
      dataIndex: 'absorbida',
      key: 'absorbida',
      align: 'center',
      render: (text, record, index) => (
        <>
          <Typography.Title level={2} style={{ margin: 0, color: 'blue' }}>
          {text}
          </Typography.Title>
          <Typography.Title level={5} style={{ margin: 0, color: 'blue' }}>
          {((text/record.todas)*100).toFixed(2)} %
          </Typography.Title>
        </>
      ),
    },
    {
      title: 'Perdidas',
      dataIndex: 'perdidas',
      key: 'perdidas',
      align: 'center',
      render: (text, record, index) => (
        <>
          <Typography.Title level={2} style={{ margin: 0, color: 'red' }}>
          {text}
          </Typography.Title>
          <Typography.Title level={5} style={{ margin: 0, color: 'red' }}>
          {((text/record.todas)*100).toFixed(2)} %
          </Typography.Title>
        </>
      ),
    },
    {
      title: 'Gestionadas',
      dataIndex: 'gestionadas',
      key: 'gestionadas',
      align: 'center',
      render: (text, record, index) => (
        <>
          <Typography.Title level={2} style={{ margin: 0, color: 'green' }}>
          {text}
          </Typography.Title>
          <Typography.Title level={5} style={{ margin: 0, color: 'green' }}>
          {((text/record.perdidas)*100).toFixed(2)} %
          </Typography.Title>
        </>
      ),
    },
    {
      title: 'Sin Gestionar',
      dataIndex: 'nogestionadas',
      key: 'nogestionadas',
      align: 'center',
      render: (text, record, index) => (
        <>
          <Typography.Title level={2} style={{ margin: 0, color: 'red' }}>
          {text}
          </Typography.Title>
          <Typography.Title level={5} style={{ margin: 0, color: 'red' }}>
          {((text/record.perdidas)*100).toFixed(2)} %
          </Typography.Title>
        </>
      ),
    },
    {
      title: 'T. respuesta',
      dataIndex: 'respuesta',
      key: 'respuesta',
      align: 'center',
      render: (text, record, index) => (
        <>
          <Typography.Title level={2} style={{ margin: 0}}>
          {text==0 && record.menos24>0 ? 0 : text && text>0 ? (text*1).toFixed(0) : "-"} horas
          </Typography.Title>
          <Typography.Title level={5} style={{ margin: 0}}>
          {record.menos24 >0 ? ((record.menos24/record.gestionadas)*100).toFixed(2) : 0} % en 24h.
          </Typography.Title>
        </>
      ),
    },
    {
      title: 'Citas',
      dataIndex: 'citas',
      key: 'citas',
      align: 'center',
      render: (text, record, index) => (
        <>
          <Typography.Title level={2} style={{ margin: 0 }}>
          {text}
          </Typography.Title>
          <Typography.Title level={5} style={{ margin: 0 }}>
          {record.gestionadas>0 ?((text/record.gestionadas)*100).toFixed(2) : 0} %
          </Typography.Title>
        </>
      )
    },
    {
      title: 'Privado',
      dataIndex: 'privado',
      key: 'privado',
      align: 'center',
      render: (text, record, index) => (
        <>
          <Typography.Title level={2} style={{ margin: 0 }}>
          {text}
          </Typography.Title>
          <Typography.Title level={5} style={{ margin: 0 }}>
          {record.citas>0 ? ((text/record.citas)*100).toFixed(2):0} %
          </Typography.Title>
        </>
      ),
    },
    {
      title: 'Compañìa',
      dataIndex: 'compania',
      key: 'compania',
      align: 'center',
      render: (text, record, index) => (
        <>
          <Typography.Title level={2} style={{ margin: 0 }}>
          {text}
          </Typography.Title>
          <Typography.Title level={5} style={{ margin: 0 }}>
          {record.citas>0 ? ((text/record.citas)*100).toFixed(2) :0} %
          </Typography.Title>
        </>
      ),
    },
  ];


  return (
    <div>
      {/* Filters */}
      <Row>
        <Col span={24}>
          <Form name="filters"
            layout='horizontal'
            // We use library dayjs only to this special and rare case
            // due to Ant Design
            initialValues={{ start: dayjs().set("date",1), fin: dayjs() }}
            onFinish={(values) => {
              getData(values);
            }}
          >
            <Space style={{ display: 'flex', marginTop: 8 }} >
              <Form.Item label="Inicio" name="start"
                rules={[{required: true, message:"Fecha de inicio requerida", type: 'object' }]}
              >
                <DatePicker format={"DD/MM/YYYY"}/>
              </Form.Item>
              <Form.Item label="Fin" name="fin"
                rules={[{required: true, message:"Fecha de fin requerida", type: 'object' }]}
              >
                <DatePicker format={"DD/MM/YYYY"}/>
              </Form.Item>
              <Form.Item >
                <Button type="primary" htmlType="submit" style={{marginLeft:'25px'}}>
                  Filtrar
                </Button>
              </Form.Item>
              </Space>
          </Form>
        </Col>
      </Row>

      <div className='ycs-container-llamadas-atendidas'>
          <div className='ycs-title-llamadas-concurrentes'>
            <Title strong level={4}>
              Llamadas totales
            </Title>
          </div>
          <Table pagination={false} 
                dataSource={[state.kpi_total, state.kpi_privadas]} 
                columns={columnsTotales}
               />
      </div>


      {/* Graphs */}
      <br/>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={16}>
          <div className='ycs-container-llamadas-concurrentes'>
            <div className='ycs-graph'>
              <div className='ycs-title-llamadas-concurrentes'>
                <Title strong level={4}>
                  Llamadas por tipo
                </Title>
              </div>

              {state.typeCalls?.length > 0 ?
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={state.typeCalls}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                    onClick={handleClick}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="dia" />
                    <YAxis />
                    {/* <Tooltip /> */}
                    <Tooltip content={<CustomTooltip /> }/>
                    <Legend />
                    {_.sortBy(state.uniqueTypeCalls, "name").map(i => (
                        <Bar dataKey={i.nombre} fill={i.color} key={i.nombre} stackId="dia" />
                    ))}
                  </BarChart>
                </ResponsiveContainer>
              :<EmptyGraph text="No hay llamadas por tipo"/> 
              }
            </div>
          </div>
        </Col>
        <Col sm={24} md={8}>
          <div className='ycs-container-llamadas-concurrentes'>
            <div className='ycs-graph'>
            <div className='ycs-title-llamadas-concurrentes'>
                <Title strong level={4}>
                  {activeIndex}
                </Title>
              </div>
              {totalCalls?.length > 0 ?
                <ResponsiveContainer width="100%" height="100%">
                <PieChart width={400} height={400}>
                <Tooltip content={<CustomTooltipPie /> }/>
                  <Pie
                    data={totalCalls}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={150}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {totalCalls.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              :<EmptyGraph text="No hay llamadas por tipo"/> 
              }
            </div>
          </div>
        </Col>
      </Row>

      <Button type="primary" style={{float: 'right'}} 
        icon={<DownloadOutlined />}
        onClick={() => getDatos()}>
        Descargar
      </Button>


    </div>
  )
}

export const EmptyGraph = ({ text }) => {
  return (
    <div className='ycs-empty-graph'>
      {text}
    </div>
  );
}