import './OficinasEstadistica.css';
import React, { useEffect, useState }   from 'react';
import { Button, Col, DatePicker, Card,
  Form, Row, Space, Typography, Statistic }        from 'antd';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, 
  XAxis, YAxis } from "recharts";
import {CheckOutlined, PhoneOutlined, CalendarOutlined, 
  UserOutlined, DownloadOutlined} from '@ant-design/icons';
import dayjs                            from "dayjs";
import moment                           from 'moment';
import { ServicesOficinas }             from '../../services/ServicesOficinas';
import _                                from 'lodash';
import { getFormatedItem, getFormatedItemOficinas }               from '../../utils/Utils';

// Constants
const { Title } = Typography;
const stateInitial = {
  currentCalls: [],
  typeCalls: [],
  uniqueTypeCalls: [],
  answeredCalls: [],
  repetitionsCalls: [],
  kpi:{},
  inicio: "",
  final: ""
}
  
export const OficinasGrafica = () => {
  ////////
  // Hooks
  ////////
  const [state, setState] = useState(stateInitial);
  
  useEffect(() => {
    // Update title
    document.title = "Icot - Hospital estadística";
    
    getData({ start: moment().set("date",1), fin: moment() });
  }, []);

  const getData = async(values) => {
    const start = values.start.format("YYYY-MM-DD");
    const fin = values.fin.format("YYYY-MM-DD");
    setState(prevState => (
      {
        ...prevState,
        inicio: start,
        final: fin
      }
    ));
    getCurrentCalls(start, fin);
    getTypeCalls(start, fin);
    getAnsweredCalls(start, fin);
    getRepetitionsCalls(start, fin);
    getKPI(start, fin);
  }
  const getDatos = async() => {
    await ServicesOficinas.getDatos(state.inicio, state.final);
  }

  const getCurrentCalls = async(start, fin) => {
    const resp = await ServicesOficinas.getConcurrents(start, fin);

    // Update state
    setState(prevState => (
      {
        ...prevState,
        currentCalls: resp
      }
    ));
  }

  const getTypeCalls = async(start, fin) => {
    const resp = await ServicesOficinas.getTypeCalls(start, fin);
    const aux = [], uniqueTypeCalls = [], typeCalls = [];
    // 1º Search unique values
    resp.forEach(i => {
      i.opciones.forEach(o => {
        aux.push(o);
      })
    });
    const unrollArray = _.groupBy(aux, "nombre");
    
    // 2º Fill color
    _.map(unrollArray, (value, key) => {
      uniqueTypeCalls.push(getFormatedItemOficinas(key))
    });

    // 3ª Fill values to show in graph
    resp.forEach(i => {
      const aux_2 = {};
      aux_2.dia = i.dia;
      i.opciones.forEach(o => {
        let property = o.nombre === null ? "SIN_DATOS" : o.nombre;
        aux_2[property] = o.total;
      });
      typeCalls.push(aux_2);
    });

    // Update state
    setState(prevState => (
      {
        ...prevState,
        typeCalls,
        uniqueTypeCalls
      }
    ));
  }

  const getAnsweredCalls = async(start, fin) => {
    const resp = await ServicesOficinas.getAnsweredCalls(start, fin);
    const answeredCalls = [];

    resp.forEach(i => {
      answeredCalls.push(
        {
          absorbidas: i.absorbidas,
          atendidas: i.atendidas - i.absorbidas,
          dia: i.dia,
          no_atendidas: i.no_atendidas
        }
      );
    })
    // Update state
    setState(prevState => (
      {
        ...prevState,
        answeredCalls
      }
    ));
  }

  const getRepetitionsCalls = async(start, fin) => {
    const resp = await ServicesOficinas.getRepetitionsCalls(start, fin);
    const repetitionsCalls = [];
    // 1º Calculate percentages
    resp.forEach(i => {
      let total = i.total + i.repeticiones;

      repetitionsCalls.push(
        {
          dia: i.dia,
          unicas: +((100 * i.total ) / total).toFixed(2),
          repetidas: +((100 * i.repeticiones ) / total).toFixed(2)
        }
      );
    })

    // Update state
    setState(prevState => (
      {
        ...prevState,
        repetitionsCalls
      }
    ));
  }

  const getKPI = async(start, fin) => {
    const kpi = await ServicesOficinas.getKPI(start, fin);
    // Update state
    setState(prevState => (
      {
        ...prevState,
        kpi
      }
    ));
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload.length > 0 && payload.length) {
      return (
        <div className='ycs-container-tooltip'>
          <p style={{ fontWeight: "bold" }}>{label}</p>
          {payload.map(i => (
            <p style={{ color: i.fill }}>{i.dataKey}: {i.value}</p>
          ))}
        </div>
      );
    }
    return null;
  };

  const CustomTooltipLlamadas = ({ active, payload, label }) => {
    if (active && payload.length > 0 && payload.length) {
      return (
        <div className='ycs-container-tooltip'>
          <p style={{ fontWeight: "bold" }}>{label}</p>
          <p style={{ fontWeight: "bold" }}>Total: {_.sum(_.map(payload,p=> parseInt(p.value)))}</p>
          {payload.map(i => (
            <p style={{ color: i.fill }}>{i.dataKey}: {i.value}</p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      {/* Filters */}
      <Row>
        <Col span={24}>
          <Form name="filters"
            layout='horizontal'
            // We use library dayjs only to this special and rare case
            // due to Ant Design
            initialValues={{ start: dayjs().set("date",1), fin: dayjs() }}
            onFinish={(values) => {
              getData(values);
            }}
          >
            <Space style={{ display: 'flex', marginTop: 8 }} >
              <Form.Item label="Inicio" name="start"
                rules={[{required: true, message:"Fecha de inicio requerida", type: 'object' }]}
              >
                <DatePicker format={"DD/MM/YYYY"}/>
              </Form.Item>
              <Form.Item label="Fin" name="fin"
                rules={[{required: true, message:"Fecha de fin requerida", type: 'object' }]}
              >
                <DatePicker format={"DD/MM/YYYY"}/>
              </Form.Item>
              <Form.Item >
                <Button type="primary" htmlType="submit" style={{marginLeft:'25px'}}>
                  Filtrar
                </Button>
              </Form.Item>
              </Space>
          </Form>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={4}>
          <Card bordered={false}>
            <Statistic groupSeparator="." title="Llamadas totales" value={state.kpi.todas} 
              prefix={<PhoneOutlined />}
              valueStyle={{ color: 'blue' }}/>
          </Card>
        </Col>
        <Col span={4}>
          <Card bordered={false}>
            <Statistic groupSeparator="." title="Respondidas" value={state.kpi.atendidas} 
              prefix={<PhoneOutlined />}
              valueStyle={{ color: 'green' }}/>
          </Card>
        </Col>
        <Col span={4}>
          <Card bordered={false}>
            <Statistic groupSeparator="." title="Perdidas" value={state.kpi.perdidas} 
              prefix={<PhoneOutlined />}
              valueStyle={{ color: 'red' }}/>
          </Card>
        </Col>
        {/* <Col span={4}>
          <Card bordered={false}>
            <Statistic groupSeparator="." title="Gestionadas" value={state.kpi.gestionadas} 
              prefix={<CheckOutlined />}    
              valueStyle={{ color: 'green' }}/>
          </Card>
        </Col>
        <Col span={4}>
          <Card bordered={false}>
            <Statistic groupSeparator="." title="Citas" value={state.kpi.citas} 
              prefix={<CalendarOutlined />}/>
          </Card>
        </Col>
        <Col span={4}>
          <Card bordered={false}>
            <Statistic groupSeparator="." title="Privado" value={state.kpi.privados} 
              prefix={<UserOutlined />}/>
          </Card>
        </Col>  */}

      </Row>
      {/* Graphs */}
      <br/>
      <div className='ycs-container-llamadas-concurrentes'>
        <div className='ycs-graph'>
          <div className='ycs-title-llamadas-concurrentes'>
            <Title strong level={4}>
              Llamadas por tipo
            </Title>
          </div>

          {state.typeCalls?.length > 0 ?
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={state.typeCalls}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="dia" />
                <YAxis />
                {/* <Tooltip /> */}
                <Tooltip content={<CustomTooltip /> }/>
                <Legend />
                {_.sortBy(state.uniqueTypeCalls, "name").map(i => (
                  <Bar dataKey={i.nombre} fill={i.color} key={i.nombre} stackId={i.name}/>
                ))}
              </BarChart>
            </ResponsiveContainer>
          :<EmptyGraph text="No hay llamadas por tipo"/> 
          }
        </div>
      </div>

      <Row gutter={16}>
        <Col md={24} lg={12} span={24}>
          <div className='ycs-container-llamadas-concurrentes'>
            <div className='ycs-graph'>
              <div className='ycs-title-llamadas-concurrentes'>
                <Title strong level={4}>
                  Llamadas atendidas
                </Title>
              </div>

              {state.answeredCalls?.length > 0 ?
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={state.answeredCalls}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="dia" />
                    <YAxis />
                    <Tooltip content={<CustomTooltipLlamadas /> }/>
                    <Bar dataKey="atendidas" stackId="a" fill="#82ca9d" />
                    <Bar dataKey="absorbidas" stackId="a" fill="#00BFFF" />
                    <Bar dataKey="no_atendidas" stackId="a" fill="#E5442D" />
                  </BarChart>
                </ResponsiveContainer>
                :<EmptyGraph text="De momento no hay llamadas atendidas"/> 
              }
            
            </div>
          </div>
        </Col>
        <Col md={24} lg={12} span={24}>
         {/*          <div className='ycs-container-llamadas-atendidas'>
            <div className='ycs-mini-graph'>
              <div className='ycs-title-llamadas-concurrentes'>
                  <Title strong level={4}>
                    Repeticiones (%)
                  </Title>
              </div>

              {state.repetitionsCalls?.length > 0 ?
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={state.repetitionsCalls}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="dia" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="unicas" stackId="a" fill="#82ca9d" />
                    <Bar dataKey="repetidas" stackId="a" fill="#E5442D" />
                  </BarChart>
                </ResponsiveContainer>
                :<EmptyGraph text="De momento no hay repeticiones"/> 
              }
            </div>
          </div> */}
          <div className='ycs-container-llamadas-concurrentes'>
            <div className='ycs-graph'>
              <div className='ycs-title-llamadas-concurrentes'>
                <Title strong level={4}>
                  Llamadas concurrentes
                </Title>
              </div>
              {state.currentCalls?.length > 0 ?
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={100}
                    data={state.currentCalls}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="hora" />
                    <YAxis />
                    <Tooltip/>
                  
                    <Bar dataKey="maximo" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              :<EmptyGraph text="De momento no hay llamadas concurrentes"/> 
              }
          </div>
      </div>
        </Col>
      </Row>

      <Button type="primary" style={{float: 'right'}} 
        icon={<DownloadOutlined />}
        onClick={() => getDatos()}>
        Descargar
      </Button>


    </div>
  )
}

export const EmptyGraph = ({ text }) => {
  return (
    <div className='ycs-empty-graph'>
      {text}
    </div>
  );
}