import { SessionStorage } from '../utils/SessionStorage';
const API_URL = process.env.REACT_APP_API_URL_OFICINAS;


export class ServicesOficinas{
  
  static getCases(estado, fecha){
    return fetch(API_URL+"cases?" + new URLSearchParams({ fecha: fecha.format("YYYY-MM-DD"), estado }))
    .then((res) => res.json());      
  }

  static getConcurrents(start, fin){
    return fetch(API_URL+"concurrentes?" + new URLSearchParams({ inicio: start, fin }))
    .then(res => res.json());
  }

  static getTypeCalls(start, fin){
    return fetch(API_URL+"tipo?" + new URLSearchParams({ inicio: start, fin }))
    .then(res => res.json());
  }

  static getAnsweredCalls(start, fin){
    return fetch(API_URL+"atendidas?" + new URLSearchParams({ inicio: start, fin }))
    .then(res => res.json());
  }

  static getRepetitionsCalls(start, fin){
    return fetch(API_URL+"repeticiones?" + new URLSearchParams({ inicio: start, fin }))
    .then(res => res.json());
  }
  static getKPI(start, fin){
    return fetch(API_URL+"kpi?" + new URLSearchParams({ inicio: start, fin }))
    .then(res => res.json());
  }
  static getDatos(start, fin){
    return fetch(API_URL+"datos?" + new URLSearchParams({ inicio: start, fin }))
    .then(res => {
      return res.blob();
    })
    .then((blob) => {
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'datos.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }
  static getAdministration(){
    return fetch(API_URL+"configuracion")
    .then(res => res.json());
  }
  static editAdministration(values){
    return fetch(API_URL+"configuracion", {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(values)
    })
    .then(res => res.json());
  }

  static changeStateWithId = (id) => {
    return fetch(API_URL+"cases/"+id+"?user="+SessionStorage.getItem("user_info")?.user, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'}              
    });
  }

  static changeStateWithPhone = (phone, inicio, estado, cita, notas, option) => {
    return fetch(API_URL+"cases/phone/" + phone + 
        "?fecha=" + inicio.format("YYYY-MM-DD")+
        "&estado=" + estado +
        "&cita=" + cita + 
        "&user=" + SessionStorage.getItem("user_info")?.user +
        (notas ? ("&notas=" + notas) : "") + 
        (option ? ("&option=" + option) : "")
      , {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'}              
    });
  }

  static changNotesWithId = (id,notas) => {
    return fetch(API_URL+"notes/"+id+"?notas="+notas+"&user="+SessionStorage.getItem("user_info")?.user, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'}              
    });
  }

  static getLlamadasTotales = (start,fin) => {
    return fetch(API_URL+"kpi_total?" + new URLSearchParams({ inicio: start, fin }))
    .then(res => res.json());
  }

  static getLlamadasPrivadas = (start,fin) => {
    return fetch(API_URL+"kpi_privado?" + new URLSearchParams({ inicio: start, fin }))
    .then(res => res.json());

  }

  static getLlamadasCampania = (start,fin) => {
    return fetch(API_URL+"kpi_campania?" + new URLSearchParams({ inicio: start, fin }))
    .then(res => res.json());
  }
}